import { Component, ViewChild, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { PrescriptionReviewService } from '../services/prescription-review.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-prescription-review',
  templateUrl: './prescription-review.component.html',
  styleUrls: ['./prescription-review.component.css']
})
export class PrescriptionReviewComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private prescriptionReviewService: PrescriptionReviewService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService) { }
  facilities: any = [];
  residents: any = [];
  eprescribers: any = [];
  patientId: number;
  condition: boolean;
  prescriptionsReview: any[];
  prescriptionsReviewId: number = 0;
  showSuccessMessage: boolean = false;
  showErrorMessage: boolean = false;
  errorMessage: string;
  months: any = [];
  monthId: any = 0;
  years: any = [];
  yearId: any = 0;
  statusId: any;

  @ViewChild('closebutton', { static: false }) closebutton;

  ngOnInit(): void {
    this.sharedService.reloadUserPrivileges();
    this.spinnerService.show;
    this.loadMonths();
    this.loadYear();
    this.patientId = 0;
    this.monthId = (new Date().getMonth()) + 1;
    this.yearId = new Date().getFullYear();
    this.loadFacilities();
    if (this.sharedService.facilityId > 0) {
      this.loadFields();
    }
  }

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    if (value == "") {
      this.patientId = 0;
      this.loadPrescriptions();
    }

    this.toHighlight = value;

    return this.getData()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        }))
      )

  }

  getPosts(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.patientId;
    this.onActionChangeResident();
  }

  getData() {
    return of(this.residents);
  }

  onActionChangeResident() {
    this.loadResidents();
    this.condition = false;
    this.loadPrescriptions();
  }

  onActionChangeMonth() {
    this.loadPrescriptions();
  }

  onActionChangeYear() {
    this.loadPrescriptions();
  }

  onActionChangeStatus() {
    this.loadPrescriptions();
  }

  onActionFacilityUnit() {
    this.loadResidents();
    this.loadPrescriptions();
  }

  loadFacilities() {
    this.sharedService.getFacilitiesByPharmacyId().subscribe(data => { this.facilities = data; });
  }

  loadFields() {
    this.loadResidents();
    this.patientId = 0
    this.statusId = 1
    this.loadPrescriptions();
  }

  loadResidents() {
    this.sharedService.getResidentsByFacilityId()
      .subscribe(data => {
        this.residents = data;
      });
  }

  loadPrescriptions() {
    var date = this.pad(this.yearId, 2) + "-" + this.pad(this.monthId, 2) + "-01";
    this.prescriptionReviewService.GetPrescriptionReviewIndexVosByPatientId(this.sharedService.pharmacyId, this.sharedService.facilityId, date, this.sharedService.getUserId(), this.statusId, this.sharedService.facilityUnitId, this.patientId)
      .subscribe(data => {
        this.prescriptionsReview = data;
        this.spinnerService.hide;
      });
  }

  openPrescriptionReview(id: any, patientId: any) {
    this.router.navigate(['prescription-review/review/' + patientId + '/' + id]);
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  loadYear() {
    const currentYear = new Date().getFullYear();
    this.years = [
      { id: currentYear - 2 },
      { id: currentYear - 1 },
      { id: currentYear }
    ];

    if (new Date() >= new Date(currentYear, 11, 1)) {
      this.years.push({ id: currentYear + 1 });
    }
  }

  loadMonths() {
    this.months.push({ id: 1, text: "Jan" });
    this.months.push({ id: 2, text: "Feb" });
    this.months.push({ id: 3, text: "Mar" });
    this.months.push({ id: 4, text: "Apr" });
    this.months.push({ id: 5, text: "May" });
    this.months.push({ id: 6, text: "Jun" });
    this.months.push({ id: 7, text: "Jul" });
    this.months.push({ id: 8, text: "Aug" });
    this.months.push({ id: 9, text: "Sep" });
    this.months.push({ id: 10, text: "Oct" });
    this.months.push({ id: 11, text: "Nov" });
    this.months.push({ id: 12, text: "Dec" });
  }

  setPrescriptionReviewId(id) {
    this.prescriptionsReviewId = id;
  }

  removePrescriptionReview() {
    this.prescriptionReviewService.deletePrescriptionReview(this.sharedService.getUserId(), this.prescriptionsReviewId).subscribe(result => {
      if (result.success) {
        this.showSuccessMessage = true;
        this.closebutton.nativeElement.click();
        this.loadPrescriptions();
        window.scroll(0, 0);
      } else {
        this.showErrorMessage = true;
        this.errorMessage = result.message;
      }
    });
  }
}

